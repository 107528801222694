<template>
  <v-app id="Main">
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
      <v-list dense>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" link :to="child.to">
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.text" :to="item.to">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="logout">ออกจากระบบ</v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-3" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>{{User.Org.OrganizationName != null?"สาขา "+User.Org.OrganizationName:""}}</v-toolbar-title>
      <v-spacer />
      {{ User.UserName }}
      <v-btn icon>
        <v-icon>mdi-bell</v-icon>
      </v-btn>
    </v-app-bar>
    <v-content>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  mounted() {
    this.Create();
  },
  computed: {
    ...mapState(["User"])
  },
  methods: {
    ...mapMutations(["GetUser"]),
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/login");
    },
    Create() {
      this.GetUser(res => {
        this.items = res;
      });
    }
  },
  data: () => ({
    dialog: false,
    drawer: null,
    items: []
  })
};
</script>
<style>
/* @import url("https://fonts.googleapis.com/css?family=Kanit&display=swap");
#Main {
  font-family: "Kanit", sans-serif;
} */
</style>
